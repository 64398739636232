import styled, { css } from "styled-components";

const Label = styled.label`
  display: inline-block;
  margin-bottom: 4px;
  color: ${p => p.theme.greyDark};

  ${p => p.required
    && css`
      position: relative;
      padding-right: 0.5em;

      &:after {
        content: "*";
        position: absolute;
        top: 0;
        right: 0;
      }
    `}

  ${p => p.error
    && p.touched
    && css`
      color: ${p.theme.red};
    `}
`;

export default Label;
