import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

const Modal = ({ isOpen, closeModal, label, children }) => {
  ReactModal.setAppElement(`#___gatsby`);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel={label}
      className="modal__inner"
      overlayClassName={{ base: `modal`, afterOpen: `modal--open`, beforeClose: `modal--closing` }}
      closeTimeoutMS={500}
    >
      <div className="modal__body">
        {children}
      </div>
    </ReactModal>   
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  label: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
};

export default Modal;
