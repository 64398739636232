import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { rem, rgba } from "polished";

import { media } from "../../utils/styleUtils";

import ErrorText from "./errorText";
import Label from "./label";

const Root = styled.div`
  input,
  textarea,
  .react-select__control,
  .StripeElement {
    background-color: ${p => p.theme.greyLightest};
    border: 1px solid ${p => p.theme.greyLight};
    border-radius: ${p => p.theme.borderRadius};
    color: ${p => p.theme.black};
    font-family: ${p => p.theme.baseFont};
    font-size: ${p => rem(`20px`, p.theme.baseFontSizeMobile)};
    line-height: 1.4;
    padding: ${p => p.theme.spacerS} ${p => p.theme.spacer};
    transition: ${p => p.theme.linkTransition};
    width: 100%;

    ${media.screenM`
      font-size: ${p => rem(`20px`, p.theme.baseFontSizeDesktop)};
      line-height: 1.33;
    `}

    &:focus {
      background-color: ${p => p.theme.white};
      border-color: ${p => p.theme.greyLight};
      outline: none;
    }

    /* If the field has a valid value */
    ${p => p.value
      && p.error !== true
      && css`
        background-color: ${p.theme.white};
        border-color: ${p.theme.greyLight};
        outline: none;
      `}

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${p => p.theme.grey};
      opacity: 1; /* Firefox */
      font-family: ${p => p.theme.secondaryFont};
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${p => p.theme.grey};
      font-family: ${p => p.theme.secondaryFont};
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${p => p.theme.grey};
      font-family: ${p => p.theme.secondaryFont};
    }

    ${p => p.error
      && p.touched
      && css`
        background-color: ${p.theme.white};
        color: ${p.theme.red};
        border-color: ${p.theme.red} !important;
      `}

    ${p => p.white
      && css`
        background-color: ${p.theme.white};
        border-color: ${p.theme.greyLight};
      `}
  }

  textarea {
    min-height: 100px;
  }

  .react-select__control {
    padding-top: 8px;
    padding-bottom: 8px;

    &:hover,
    &--is-focused {
      background-color: ${p => p.theme.white};
      border-color: ${p => p.theme.greyLight};
    }

    &--is-focused {
      box-shadow: none;
    }
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    padding: 0;

    svg {
      fill: ${p => p.theme.grey};
    }
  }

  .react-select__menu {
    border: 1px solid ${p => p.theme.greyLight};
    box-shadow: ${p => p.theme.boxShadowM};
    border-radius: ${p => p.theme.borderRadius};
  }

  .react-select__option--is-focused {
    background-color: ${p => rgba(p.theme.primary, 0.2)};
  }

  .react-select__option--is-selected {
    background-color: ${p => p.theme.primary};
  }

  .StripeElement {
    padding-top: 16px;
    padding-bottom: 16px;

    &--focus {
      background-color: ${p => p.theme.white};
      border-color: ${p => p.theme.greyLight};
    }

    &--invalid {
      background-color: ${p => p.theme.white};
      color: ${p => p.theme.red};
      border-color: ${p => p.theme.red} !important;
    }
  }
`;

const StyledErrorText = styled(ErrorText)`
  margin-top: ${p => p.theme.spacerXS};
`;

const InputTemplate = ({
  name,
  label,
  value,
  required,
  error,
  touched,
  children,
  ...rest
}) => {
  return (
    <Root value={value} error={error} touched={touched} {...rest}>
      {label && (
        <Label
          htmlFor={name}
          required={required}
          error={error}
          touched={touched}
        >
          {label}
        </Label>
      )}

      <div>{children}</div>

      {touched && error && <StyledErrorText>{error}</StyledErrorText>}
    </Root>
  );
};

InputTemplate.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.array,
  ]),
  required: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default InputTemplate;
