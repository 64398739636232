import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import { humaniseString } from 'utils/stringConversion';
import Heading from 'components/typography/heading';
import StackedList from 'components/lists/stackedList';
import ListItem from 'components/lists/listItem';
import HelpTeaser from 'components/pages/help-centre/helpTeaser';

const CategoryContainer = styled(Box)`
  border-bottom: 1px solid ${p => p.theme.greyLight};
`;

const CategoryRow = ({ title, category }) => {
  return (
    <CategoryContainer pt={[8, 8, `100px`]} pb={[5, 5, `40px`]}>
      <Heading rank="h2" style="h2" margin="0">{title}</Heading>

      <Flex flexWrap="wrap" mx={[-3, -3, -5]}>
        {category && Object.keys(category).map((topic) => {
          const currentTopic = category[topic];

          return (
            <Box key={topic} width={[1, 1, 4 / 12]} px={[3, 3, 5]} my={[3, 3, `60px`]}>
              <Box mb={3}>
                <Heading rank="h3" style="h3" weight="bold" margin="0">{humaniseString(topic)}</Heading>
              </Box>

              <StackedList>
                {currentTopic.map((article) => {
                  const { node: { frontmatter: {
                    path: postPath, title: postTitle,
                  } } } = article;

                  return (
                    <ListItem key={postPath}>
                      <HelpTeaser
                        title={postTitle}
                        linkTo={postPath}
                      />
                    </ListItem>
                  );
                })}
              </StackedList>
            </Box>
          );
        })}
      </Flex>
    </CategoryContainer>
  );
};

CategoryRow.propTypes = {
  title: PropTypes.string,
  category: PropTypes.object.isRequired,
};

export default CategoryRow;
