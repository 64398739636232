import styled, { css } from "styled-components";

const InputSpacer = styled.div`
  margin-bottom: ${p => p.theme.spacer};

  ${p => p.size === `l`
    && css`
      margin-bottom: ${p.theme.spacerL};
    `}
`;

export default InputSpacer;
