import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import Select from "react-select";

import InputTemplate from "./inputTemplate";

const SelectInput = ({
  formik,
  name,
  label,
  value,
  required,
  error,
  touched,
  onChange,
  onBlur,
  children,
  theme,
  ...rest
}) => {
  // Example of how to integrate Formik with third-party input components
  // https://codesandbox.io/s/jRzE53pqR
  // This is going to call setFieldValue and manually update values.name
  const handleChange = (e) => {
    if (formik === true) {
      onChange(name, e.value);
    } else {
      onChange && onChange(e);
    }
  };

  const handleBlur = (e) => {
    // This is going to call setFieldTouched and manually update touched.name
    if (formik === true) {
      onBlur(name, true);
    } else {
      onBlur && onBlur(e);
    }
  };

  // https://react-select.com/styles#provided-styles-and-state
  // These are the only styles that have to be added via the React-Select style object
  // instead of class names as React-Select doesn't add a .react-select__control--has-value
  // modifier class, it adds it one level deeper on the .react-select__value-container
  // which is not useful to us as we need conditional styling on the control when a value is present.
  // The !important is needed to override the classname styles in inputTemplate.js
  const customStyles = {
    control: (provided, state) => {
      const { hasValue } = state;
      const backgroundColor = hasValue && `${theme.white} !important`;
      const borderColor = hasValue && `${theme.greyLight} !important`;

      return { ...provided, backgroundColor, borderColor };
    },
  };

  return (
    <InputTemplate
      name={name}
      label={label}
      value={value}
      required={required}
      error={error}
      touched={touched}
    >
      {children}

      <Select
        id={name}
        name={name}
        required={required}
        value={value}
        error={error}
        touched={touched}
        onChange={handleChange}
        onBlur={handleBlur}
        className="react-select"
        classNamePrefix="react-select"
        styles={customStyles}
        {...rest}
      />
    </InputTemplate>
  );
};

SelectInput.propTypes = {
  formik: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  required: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  theme: PropTypes.object.isRequired,
};

export default withTheme(SelectInput);
