import React from "react";
import PropTypes from "prop-types";

import InputTemplate from "./inputTemplate";

const Input = ({
  name,
  label,
  value,
  maxLength,
  required,
  error,
  touched,
  children,
  white,
  ...rest
}) => {
  return (
    <InputTemplate
      name={name}
      label={label}
      value={value}
      required={required}
      error={error}
      touched={touched}
      white={white}
    >
      {children}

      <input
        id={name}
        name={name}
        value={value}
        maxLength={maxLength}
        required={required}
        {...rest}
      />
    </InputTemplate>
  );
};

Input.defaultProps = {
  type: `text`,
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  white: PropTypes.bool,
};

export default Input;
