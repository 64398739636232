import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { rem } from "polished";

import { media } from "../../utils/styleUtils";

import ErrorText from "./errorText";

const Root = styled.div`
  position: relative;

  input {
    opacity: 0;
    position: absolute;

    & + label:before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 80%;
    }

    &[type="checkbox"] + label:before {
      border-radius: ${p => p.theme.borderRadius};
    }

    &[type="radio"] + label:before {
      border-radius: 50%;
    }

    &:checked + label:before {
      background-image: url(/svg/checkbox-radio-tick.svg);
      background-color: ${p => p.theme.primary};
      border-color: ${p => p.theme.primary};
    }

    &:focus + label:before {
      outline: 1px dotted #212121;
      outline: 5px auto -webkit-focus-ring-color;
    }

    &:disabled + label:before {
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

const Label = styled.label`
  display: inline-flex;
  margin-bottom: 0;
  text-align: left;
  cursor: pointer;
  color: ${p => p.theme.greyDark};

  &:before {
    position: relative;
    flex-shrink: 0;
    content: "";
    display: block;
    background-color: ${p => p.theme.greyLightest};
    border: 1px solid ${p => p.theme.greyLight};
    height: ${p => p.theme.radioCheckboxSize};
    width: ${p => p.theme.radioCheckboxSize};
    margin-right: ${p => p.theme.spacerS};
    transition: ${p => p.theme.linkTransition};
  }

  ${p => p.error
    && p.touched
    && css`
      &:before {
        background-color: ${p.theme.white};
        border-color: ${p.theme.red};
      }
    `}
`;

const StyledErrorText = styled(ErrorText)`
  margin-top: ${p => p.theme.spacerXS};
`;

const CheckboxRadioTemplate = ({
  id,
  label,
  value,
  required,
  error,
  touched,
  children,
}) => {
  return (
    <Root value={value} error={error} touched={touched}>
      {children}

      <Label htmlFor={id} required={required} error={error} touched={touched}>
        <div>
          {label}
          {touched && error && <StyledErrorText>{error}</StyledErrorText>}
        </div>
      </Label>
    </Root>
  );
};

CheckboxRadioTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default CheckboxRadioTemplate;
