import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import BasicLink from 'components/links/basicLink';

const HelpTeaser = ({ title, linkTo }) => {
  return (
    <div>
      <BasicLink to={linkTo} weight="normal" black="true">{title}</BasicLink>
    </div>
  );
};

HelpTeaser.propTypes = {
  title: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default HelpTeaser;

export const query = graphql`
  fragment HelpTeaserFragment on MarkdownRemark {
    frontmatter {
      path
      topic
      title
      order
    }
  }
`;
