import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Box } from '@rebass/grid';
import renderHTML from 'react-render-html';

import GreenPatternBg from 'components/patterns/greenPatternBg';
import Container from 'components/grid/container';
import MaxWidth from 'components/grid/maxWidth';
import Heading from 'components/typography/heading';
import Text from 'components/typography/text';

const Root = styled(Box)`
  text-align: center;
  height: 50vh;
  min-height: 400px;
  display: flex;
  align-items: center;
`;

const BasicHero = ({ title, subtitle, children }) => {
  return (
    <Root as={GreenPatternBg} py={[10, 10, `100px`]}>
      <Container>
        <MaxWidth>
          <Heading rank="h1" style="h1" margin="0">{renderHTML(title)}</Heading>
          {subtitle && (
            <Box pt={5}>
              <Text style="h4" margin="0">{renderHTML(subtitle)}</Text>
            </Box>
          )}
          {children}
        </MaxWidth>
      </Container>
    </Root>
  );
};

BasicHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.object,
};

export default BasicHero;

export const query = graphql`
  fragment BasicHeroFragment on MarkdownRemark {
    frontmatter {
      hero {
        title
        subtitle
      }
    }
  }
`;
