import styled from "styled-components";

const ErrorText = styled.p`
  color: ${p => p.theme.red};
  margin-bottom: 0;
`;

ErrorText.defaultProps = {
  className: `small`,
};

export default ErrorText;
