import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Box } from "@rebass/grid";

import { lowercaseHyphen } from "utils/stringConversion";
import Container from "components/grid/container";
import BasicHero from "components/heros/basicHero";
import Button from "components/buttons/button";
import SignUpCta from "components/ctas/signUpCta";
import CategoryRow from "components/pages/help-centre/categoryRow";
import Modal from "components/modals/modal";
import ModalHeader from "components/modals/modalHeader";
import ContactForm from "components/forms/contactForm";
import { createHubspotContactUsTicket } from "actions/formActions";
import Notification from "components/notifications/notification";

const getFilteredTopics = (array, value) => {
  return array.filter((item) => {
    return item.node.frontmatter.topic === value;
  });
};

class HelpCentre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageModalOpen: false,
      loading: false,
      notificationResponse: ``,
    };
  }

  closeModal = (modal) => {
    this.setState({ [modal]: false });
  };

  handleContactUsFormSubmit = (values) => {
    values = {
      ...values,
      pageName: `Contact Us - The Coaching Manual`,
      pageUri: `https://www.thecoachingmanual.com/contact/`,
    };

    createHubspotContactUsTicket(values)
      .then(() => {
        this.setState({ messageModalOpen: false }, () => {
          this.stopFormSubmit();
        });

        this.setState({ notificationResponse: `success` });
        setTimeout(() => {
          this.setState({ notificationResponse: `` });
        }, 6000);
      })
      .catch(() => {
        this.setState({ messageModalOpen: false }, () => {
          this.stopFormSubmit();
        });

        this.setState({ notificationResponse: `error` });
        setTimeout(() => {
          this.setState({ notificationResponse: `` });
        }, 6000);
      });
  };

  stopFormSubmit = () => {
    this.setState({ loading: false });
  };

  render() {
    const {
      markdownRemark: {
        frontmatter: { hero },
      },
      popular,
      features,
    } = this.props.data;
    const { messageModalOpen, loading, notificationResponse } = this.state;

    const popularCategory = {};
    if (popular && popular.edges) {
      popular.edges.forEach((article) => {
        const {
          node: {
            frontmatter: { topic },
          },
        } = article;

        const machineName = lowercaseHyphen(topic);
        popularCategory[machineName] = getFilteredTopics(popular.edges, topic);
      });
    }

    const featureCategory = {};
    if (features && features.edges) {
      features.edges.forEach((article) => {
        const {
          node: {
            frontmatter: { topic },
          },
        } = article;

        const machineName = lowercaseHyphen(topic);
        featureCategory[machineName] = getFilteredTopics(features.edges, topic);
      });
    }

    return (
      <React.Fragment>
        {notificationResponse === `success` && (
          <Notification
            message="Your message has been successfully sent."
            type="success"
          />
        )}
        {notificationResponse === `error` && (
          <Notification
            message="There was an issue sending your message. You can always get in touch at help@thecoachingmanual.com."
            type="error"
          />
        )}

        <BasicHero title={hero.title} subtitle={hero.subtitle}>
          <Box pt={5}>
            <Button
              variant="secondary"
              onClick={() => this.setState({ messageModalOpen: true })}
            >
              Submit a support ticket
            </Button>
          </Box>
        </BasicHero>

        <Container>
          <CategoryRow title="Popular Topics" category={popularCategory} />
          <CategoryRow title="Features" category={featureCategory} />
        </Container>

        <SignUpCta />

        <Modal
          isOpen={messageModalOpen}
          closeModal={() => this.closeModal(`messageModalOpen`)}
          label="Support form modal"
        >
          <ModalHeader
            title="Submit a support ticket"
            onCloseClick={() => this.closeModal(`messageModalOpen`)}
          />

          <Box p={3}>
            <ContactForm
              loading={loading}
              onSubmit={(values) => {
                this.setState({ loading: true }, () => {
                  this.handleContactUsFormSubmit(values);
                });
              }}
            />
          </Box>
        </Modal>
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...LayoutFragment
      ...BasicHeroFragment
    }
    popular: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: { eq: "help-article" }
          category: { eq: "Popular Topics" }
        }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          ...HelpTeaserFragment
        }
      }
    }
    features: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: { eq: "help-article" }
          category: { eq: "Features" }
        }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          ...HelpTeaserFragment
        }
      }
    }
  }
`;

HelpCentre.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HelpCentre;
