import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { Flex, Box } from "@rebass/grid";
import * as Yup from "yup";

import Form from "components/forms/form";
import Text from "components/typography/text";
import Input from "components/inputs/input";
import Select from "components/inputs/selectInput";
import Checkbox from "components/inputs/checkboxInput";
import Textarea from "components/inputs/textareaInput";
import InputSpacer from "components/inputs/inputSpacer";
import Button from "components/buttons/button";

const contactMessageForm = ({
  loading,
  // Formik props
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  setFieldTouched,
}) => {
  const options = [
    {
      value: `General`,
      label: `General`,
    },
    {
      value: `Technical`,
      label: `Technical`,
    },
    {
      value: `Billing`,
      label: `Billing`,
    },
    {
      value: `feature_request`,
      label: `Feature Request`,
    },
  ];

  return (
    <Form>
      <Flex flexWrap="wrap">
        <Box pr={[0, 2]} py={2} width={[1, 1 / 2]}>
          <Input
            name="firstname"
            label="First name"
            value={values.firstname}
            onChange={handleChange}
            error={errors.firstname}
            touched={touched.firstname}
            required
          />
        </Box>
        <Box pl={[0, 2]} py={2} width={[1, 1 / 2]}>
          <Input
            name="lastname"
            label="Last name"
            value={values.lastname}
            onChange={handleChange}
            error={errors.lastname}
            touched={touched.lastname}
            required
          />
        </Box>

        <Box pr={[0, 2]} py={2} width={[1, 1 / 2]}>
          <Input
            name="email"
            label="Email address"
            type="email"
            value={values.email}
            onChange={handleChange}
            error={errors.email}
            touched={touched.email}
            required
          />
        </Box>
        <Box pl={[0, 2]} py={2} width={[1, 1 / 2]}>
          <Input
            name="phone"
            label="Phone Number"
            type="tel"
            value={values.phone}
            onChange={handleChange}
            error={errors.phone}
            touched={touched.phone}
          />
        </Box>

        <Box pr={[0, 2]} py={2} width={[1, 1 / 2]}>
          <Input
            name="issue"
            label="Issue"
            value={values.issue}
            onChange={handleChange}
            error={errors.issue}
            touched={touched.issue}
            maxLength="20"
            required
          />
        </Box>
        <Box pl={[0, 2]} py={2} width={[1, 1 / 2]}>
          <Select
            name="issueType"
            label="Issue Type"
            options={options}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            error={errors.issueType}
            touched={touched.issueType}
            required
            formik
          />
        </Box>

        <Box py={2} width={1}>
          <Textarea
            name="message"
            label="Issue Details"
            value={values.message}
            onChange={handleChange}
            error={errors.message}
            touched={touched.message}
            required
          />
        </Box>

        <Text>
          The Coaching Manual is committed to protecting and respecting your
          privacy, and we’ll only use your personal information to administer
          your account and to provide the products and services you requested
          from us. From time to time, we would like to contact you about our
          products and services, as well as other content that may be of
          interest to you. If you consent to us contacting you for this purpose,
          please tick below to say how you would like us to contact you:
        </Text>
        <Box mb={3}>
          <Checkbox
            name="comsAgreement"
            label="I agree to receive other communications from The Coaching Manual."
            value={values.comsAgreement}
            onChange={handleChange}
            error={errors.comsAgreement}
            touched={touched.comsAgreement}
            required
          />
        </Box>
        <Text>
          You can unsubscribe from these communications at any time. For more
          information on how to unsubscribe, our privacy practices, and how we
          are committed to protecting and respecting your privacy, please review
          our Privacy Policy.
        </Text>
        <Text>
          By clicking submit below, you consent to allow The Coaching Manual to
          store and process the personal information submitted above to provide
          you the content requested.
        </Text>

        <InputSpacer className="w-100 text-right">
          <Button as="button" type="submit" loading={loading}>
            Submit
          </Button>
        </InputSpacer>
      </Flex>
    </Form>
  );
};

contactMessageForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  // Formik
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

const FormikContactMessageForm = withFormik({
  mapPropsToValues({ data }) {
    let initialData = {};
    if (data) {
      initialData = data;
    }

    return {
      firstname: initialData.firstName || ``,
      lastname: initialData.lastName || ``,
      email: initialData.email || ``,
      phone: initialData.phone || ``,
      issue: initialData.issue || ``,
      issueType: initialData.issueType || ``,
      message: initialData.message || ``,
      comsAgreement: initialData.comsAgreement || false,
    };
  },
  validationSchema: Yup.object().shape({
    firstname: Yup.string()
      .label(`First name`)
      .required(),
    lastname: Yup.string()
      .label(`Last name`)
      .required(),
    email: Yup.string()
      .label(`Email address`)
      .email()
      .required(),
    phone: Yup.number()
      .label(`Phone number`)
      .typeError(`Must be a number`),
    issue: Yup.string()
      .label(`Issue`)
      .required(),
    issueType: Yup.string()
      .label(`Issue Type`)
      .oneOf([`Technical`, `Billing`, `feature_request`, `General`])
      .required(),
    message: Yup.string()
      .label(`Issue Details`)
      .required(),
    comsAgreement: Yup.boolean(),
  }),
  handleSubmit(values, bag) {
    bag.props.onSubmit(values);
  },
})(contactMessageForm);

export default FormikContactMessageForm;
