import React from 'react';
import styled from 'styled-components';

import { CloseIcon } from 'components/icons/icons';
import SrOnly from 'components/misc/srOnly';

const Root = styled.button`
  * {
    float: left;
  }
`;

const CloseButton = ({ ...props }) => (
  <Root {...props}>
    <CloseIcon size="s" />
    <SrOnly>Close</SrOnly>
  </Root>
);

export default CloseButton;
