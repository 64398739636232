import React from "react";
import PropTypes from "prop-types";

import CheckboxRadioTemplate from "./checkboxRadioTemplate";

const CheckboxInput = ({
  name,
  label,
  value,
  required,
  error,
  touched,
  onChange,
  ...rest
}) => {
  const preventFocus = (e) => {
    e.currentTarget.blur();
    if (onChange) onChange(e);
  };

  return (
    <CheckboxRadioTemplate
      id={name}
      label={label}
      value={value}
      required={required}
      error={error}
      touched={touched}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        value={value}
        onChange={preventFocus}
        {...rest}
      />
    </CheckboxRadioTemplate>
  );
};

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckboxInput;
