import handleErrors from "utils/handleErrors";
import formSentSuccess from "../components/notifications/notification";

export async function createHubspotContactUsTicket(values) {
  const {
    firstname,
    lastname,
    email,
    phone,
    issue,
    issueType,
    message,
  } = values;

  const body = {
    first_name: firstname,
    last_name: lastname,
    email_address: email,
    phone_number: phone,
    issue_title: issue,
    issue_type: issueType,
    issue_details: message,
    source: `TCM_FORM`,
  };

  try {
    const response = await fetch(
      `https://us-central1-dev-toptekkers-private.cloudfunctions.net/generate_hubspot_issue`,
      {
        method: `POST`,
        body: JSON.stringify(body),
        headers: {
          "Content-Type": `application/json`,
          Accept: `application/json`,
        },
      },
    );

    handleErrors(await response);
  } catch (err) {
    throw err;
  }
}
