import styled, { withTheme } from 'styled-components';

import { media } from 'utils/styleUtils';
import bgPattern from 'images/brand-pattern-on-primary.png';

const GreenPatternBg = styled.div`
  background-color: ${p => p.theme.primary};
  background-image: url(${bgPattern});
  background-size: 280px 280px;
  background-repeat: repeat;
  position: relative;
  color: ${p => p.theme.white};

  ${media.screenM`
    background-size: 560px 560px;
  `}
`;

export default withTheme(GreenPatternBg);
