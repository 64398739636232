import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import Heading from 'components/typography/heading';
import CloseButton from 'components/buttons/closeButton';

const Root = styled(Flex)`
  border-bottom: 1px solid ${p => p.theme.greyLight};
`;

const StyledCloseButton = styled(CloseButton)`
  margin-left: auto;
`;

const ModalHeader = ({ title, onCloseClick }) => (
  <Root p={3} alignItems="center">
    <Heading rank="h2" style="h3" weight="bold" margin="0">{title}</Heading>
    <StyledCloseButton onClick={onCloseClick} />
  </Root>
);

ModalHeader.propTypes = {
  title: PropTypes.string,
  onCloseClick: PropTypes.func,
};

export default ModalHeader;
