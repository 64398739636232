import React from "react";
import PropTypes from "prop-types";
import { Form } from "formik";

// Default Formik <Form> element doesn't include noValidate prop
// to prevent the default browser validation so use this instead
const Root = ({ children }) => {
  return <Form noValidate>{children}</Form>;
};

Root.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Root;
