import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { media } from "../../utils/styleUtils";

const Root = styled.div`
  z-index: 9999;
  position: fixed;
  top: 70px;
  right: 0;
  padding: ${p => p.theme.spacerS} ${p => p.theme.spacerM};
  color: ${p => p.theme.white};
  background: ${p => p.theme.black};
  border: 1px solid ${p => p.theme.blackark};
  border-radius: ${p => p.theme.borderRadius};
  transition: 0.35s ease;
  max-width: 470px;

  > * {
    margin: 0;
    padding: 0;
  }

  ${media.screenS`
    right: 20px;
  `}

  ${p => p.type === `success`
    && `
    background: ${p.theme.secondary};
    border: 1px solid ${p.theme.secondaryDark};
  `}

  ${p => p.type === `error`
    && `
    background: ${p.theme.red};
    border: 1px solid ${p.theme.redDark};
  `}
`;

const Notification = ({ type, message }) => {
  return (
    <Root type={type}>
      <p>{message}</p>
    </Root>
  );
};

Notification.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Notification;
